import styles from "./SettingsPage.module.css"
import { NavLink, useLocation } from 'react-router-dom';
import ParametersPage from './../../components/SettingsPage/ParametersPage/ParametersPage.jsx';
import PasswordPage from './../../components/SettingsPage/PasswordPage/PasswordPage.jsx';
import ProfilePage from "../../components/SettingsPage/ProfilePage/ProfilePage.jsx";
import RegistryPage from "../../components/SettingsPage/RegistryPage/RegistryPage.jsx";


function SettingsPage() {
    const location = useLocation(); // получаем текущий путь

    return (
      <div>
        <div className={styles.settingsHeader}>
            <ul>
                <NavLink
                to="/settings/profile"
                style={({ isActive }) => ({
                    borderBottom: isActive ? '2px solid #6366F1' : '',
                    color: isActive ? '#6366F1' : '',
                })}>
                    <li>
                    Профиль
                    </li>
                </NavLink>
                <NavLink
                to="/settings/day"
                style={({ isActive }) => ({
                    borderBottom: isActive ? '2px solid #6366F1' : '',
                    color: isActive ? '#6366F1' : '',
                })}>
                    <li>
                    Параметры суток
                    </li>
                </NavLink>
                <NavLink
                to="/settings/registry"
                style={({ isActive }) => ({
                    borderBottom: isActive ? '2px solid #6366F1' : '',
                    color: isActive ? '#6366F1' : '',
                })}>
                    <li>
                    Реестр должностей
                    </li>
                </NavLink>
                <NavLink
                to="/settings/password"
                style={({ isActive }) => ({
                    borderBottom: isActive ? '2px solid #6366F1' : '',
                    color: isActive ? '#6366F1' : '',
                })}>
                    <li>
                    Пароли
                    </li>
                </NavLink>
            </ul>
        </div>

        {location.pathname == '/settings/profile' && <ProfilePage />}
        {location.pathname == '/settings/day' && <ParametersPage />}
        {location.pathname == '/settings/registry' && <RegistryPage />}
        {location.pathname == '/settings/password' && <PasswordPage />}
        {/* <button>Запланировать новую ставку</button> */}
      </div>
    );
}

export default SettingsPage;
  