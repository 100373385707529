import styles from './Table.module.css'
import job_title_arrow from '../../assets/Table/job_title_arrow.svg'
import { ReactComponent as TitlePlus } from '../../assets/Table/title_plus.svg';
import { useRef, useState } from 'react'
import AddEmployeeModal from '../MainPage/AddEmployeeModal/AddEmployeeModal';

function Table({JobTitleName, leftPart, topPart, widthPart, canAddJobTitle, date, tableNumber}) {
    const [hideList, setHideList] = useState(false)
    const [dayModal, setDayModal] = useState(false)
    const [addEmployeeModal, setAddEmployeeModal] = useState(false)

    let das = ['', '', '']
    const jobTitleContainerRef = useRef(null);

    function fillArrayToMatchLength(arrayToFill, referenceArray) {
        let difference;
        if(!topPart && !date) {
          difference = 9 - arrayToFill.length
        } else {
            difference = referenceArray.length - arrayToFill.length
        }
      
        // Если длина меньше, создаем новый массив с дополнением пустыми строками
        if (difference > 0) {
          return [...arrayToFill, ...Array(difference).fill("")];
        }
      
        // Если длина уже соответствует или больше, возвращаем исходный массив
        return arrayToFill;
    }

    function getDaysArrayInCurrentMonth() {
        // Получаем текущую дату
        const now = new Date();
      
        // Получаем текущий год и месяц
        const year = now.getFullYear();
        const month = now.getMonth(); // Месяцы в JavaScript начинаются с 0
      
        // Получаем количество дней в текущем месяце
        const daysInMonth = new Date(year, month + 1, 0).getDate();
      
        // Создаем массив с днями
        const daysArray = Array.from({ length: daysInMonth }, (_, i) => i + 1);
      
        return daysArray;
    }
      
    let topArray = date ? getDaysArrayInCurrentMonth() : topPart ? topPart : null;
    const updatedArray = fillArrayToMatchLength(das, topArray);
    
    return (
        <div className={styles.table_container} style={{maxHeight: hideList ? `${jobTitleContainerRef.current.offsetHeight}px` : ''}}>
            <div className={styles.top_part_container}>
                <div className={styles.job_title_container}>
                    <span ref={jobTitleContainerRef} style={{width: canAddJobTitle === 'true' ? '150px' : '225px', maxWidth: canAddJobTitle === 'true' ? '150px' : '225px'}}>
                        {JobTitleName}
                        <img src={job_title_arrow} style={{rotate: hideList ? '270deg' : ''}} alt="" onClick={() => {setHideList(!hideList)}}/>
                    </span>
                    {canAddJobTitle === 'true' && <div className={styles.table_plus} style={{color: '#6366F1'}}><TitlePlus /></div>}
                </div>
                <div className={styles.top_part_list}>
                    {hideList && !topArray && <div className={styles.top_part_container_statistics}>
                        <div className={styles.top_part_elem_statistics} style={{width: widthPart}}>
                            120
                        </div>
                    </div>}
                    {!hideList && topArray && topArray.map((el, index) => {
                        return (
                            <div style={{width: widthPart}} className={styles.top_list_item} key={index} id={`top_${tableNumber}_index_${index}`}>
                                {el}
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className={styles.bottom_part_container}>
                {leftPart.map((el, index) => {
                    return (
                        <div className={styles.left_part} key={index}>
                            <div className={styles.name_container}>
                                <img src="" alt="" />
                                <span>
                                    {el}
                                </span>
                            </div>
                            <div className={styles.data_container}>
                                {updatedArray.map((el, index) => {
                                    return (
                                        <div onDoubleClick={() => setDayModal(!dayModal)} key={`${index}_ind`} className={styles.bottom_part_elem} style={{width: widthPart, height: '40px', borderRadius: index === 0 ? '10px 0 0 10px' : index + 1 === updatedArray.length ? '0 10px 10px 0' : '', borderRight: index + 1 === updatedArray.length ? '1px solid #6366F1' : ''}} onMouseEnter={() => {document.getElementById(`top_${tableNumber}_index_${index}`).style.background = "rgb(242, 249, 255)"}} onMouseLeave={() => {document.getElementById(`top_${tableNumber}_index_${index}`).style.background = "white"}}>
                                            {el}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}  
                {canAddJobTitle === 'true'  && <div onClick={() => setAddEmployeeModal(!addEmployeeModal)} className={styles.botton_add} style={{color: '#A4B9D0'}}>
                    Добавить 
                    <TitlePlus />
                </div>}
            </div>
            <AddEmployeeModal seeModal={addEmployeeModal} changeModal={() => setAddEmployeeModal(!addEmployeeModal)} />
        </div>
    )
}

export default Table;
  