import { useEffect } from 'react';
import styles from './DayTable.module.css';

function DayTable({ time }) {
  const hours = ['0', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'];
  const sticks = Array(96).fill(null); 

  const convertMinutesToPixels = (startTime, endTime) => {
    const startHour = parseInt(startTime.split(':')[0]);
    const endHour = parseInt(endTime.split(':')[0]);

    const startElement = document.querySelectorAll('#hours_container li')[startHour];
    const startElementNext = document.querySelectorAll('#hours_container li')[startHour + 1];
    const endElement = document.querySelectorAll('#hours_container li')[endHour];
    const endElementNext = document.querySelectorAll('#hours_container li')[endHour + 1];

    if (!startElement || !endElement || !startElementNext || !endElementNext) return 0;

    // Получаем размеры элементов
    const startRect = startElement.getBoundingClientRect();
    const startRectNext = startElementNext.getBoundingClientRect();
    const endRect = endElement.getBoundingClientRect();
    const endRectNext = endElementNext.getBoundingClientRect();

    // Расстояние между началом следующего элемента и концом текущего элемента
    const distanceBetweenStartAndNext = startRectNext.left - startRect.right;
    const distanceBetweenEndAndNext = endRectNext.left - endRect.right;

    let firstPixels = distanceBetweenStartAndNext / 60 * parseInt(startTime.split(':')[1])
    let secondPixels = distanceBetweenEndAndNext / 60 * parseInt(endTime.split(':')[1])

    if(firstPixels !== 0) {
      firstPixels += 10
    }

    if(secondPixels !== 0) {
      secondPixels += 10
    }

    return [firstPixels, secondPixels]
  };


  const convertTimeToPercentage = (timeString) => {
    const [hour] = timeString.split(':').map(Number);
    const element = document.querySelectorAll('#hours_container li')[hour];
    const container = document.querySelector('#hours_container');

    if (!element || !container) return { start: 0, end: 0 };

    let elementRect = element.getBoundingClientRect();
    let containerRect = container.getBoundingClientRect();

    let start = elementRect.left - containerRect.left
    let end = containerRect.right - elementRect.right
    if(timeString === '00:00') {
      end = 0
    }

    return {
      start: start,
      end: end
    };
  };

  useEffect(() => {
    // Переводим интервалы времени в проценты и пиксели
    const timeIntervals = {
      first: {
        start: convertTimeToPercentage(time.first.first),
        end: convertTimeToPercentage(time.first.second),
        minutesPixels: convertMinutesToPixels(time.first.first, time.first.second),
      },
      second: {
        start: convertTimeToPercentage(time.second.first),
        end: convertTimeToPercentage(time.second.second),
        minutesPixels: convertMinutesToPixels(time.second.first, time.second.second),
      },
      third: {
        start: convertTimeToPercentage(time.third.first),
        end: convertTimeToPercentage(time.third.second),
        minutesPixels: convertMinutesToPixels(time.third.first, time.third.second),
      },
      fourth: {
        start: convertTimeToPercentage(time.fourth.first),
        end: convertTimeToPercentage(time.fourth.second),
        minutesPixels: convertMinutesToPixels(time.fourth.first, time.fourth.second),
      }
    };
  
    // Обновляем стили для каждого временного интервала
    Object.keys(timeIntervals).forEach((key, index) => {
      const el = timeIntervals[key];
      const lineContainer = document.querySelector(`#active_line_container_${index}`);
      
      if (lineContainer) {
        lineContainer.style.left = `${el.start.start === 0 ? el.start.start + el.minutesPixels[0] : el.start.start + el.minutesPixels[0] + 6}px`;
        
        // Если second равно "00:00", то линия должна доходить до конца
        const rightValue = el.end.end === 0 ? '0px' : `${el.end.end - el.minutesPixels[1] + 5}px`;
        lineContainer.style.right = rightValue;
      }
    });
  }, [time]);
  

  return (
    <div className={styles.table_container}>
      <ul id='hours_container'>
        {hours.map((el, index) => (
          <li key={index}>{el}</li>
        ))}
      </ul>
      <div className={styles.sticks_container}>
        {sticks.map((_, index) => (
          <div key={index} className={styles.stick}></div>
        ))}
      </div>
      <div className={styles.empty_lines_container}>
        <div className={styles.empty_line_container}>
          <div className={styles.active_line_container} id='active_line_container_0'></div>
        </div>
        <div className={styles.empty_line_container}>
          <div className={styles.active_line_container} id='active_line_container_1' style={{background: '#FF9500'}}></div>
        </div>
        <div className={styles.empty_line_container}>
          <div className={styles.active_line_container} id='active_line_container_2' style={{background: '#BFBFBF'}}></div>
        </div>
        <div className={styles.empty_line_container}>
          <div className={styles.active_line_container} id='active_line_container_3' style={{background: '#3E3E3E'}}></div>
        </div>
      </div>
      <div className={styles.colors_container}>
        <div className={styles.color_container}>
          <div className={styles.color} style={{ background: '#DFE709' }}></div>
          День
        </div>
        <div className={styles.color_container}>
          <div className={styles.color} style={{ background: '#FF9500' }}></div>
          Утро
        </div>
        <div className={styles.color_container}>
          <div className={styles.color} style={{ background: '#BFBFBF' }}></div>
          Вечер
        </div>
        <div className={styles.color_container}>
          <div className={styles.color} style={{ background: '#3E3E3E' }}></div>
          Ночь
        </div>
      </div>
    </div>
  );
}

export default DayTable;
