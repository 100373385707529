import show_password from "../../../assets/LoginPage/show_password.svg";
import hide_password from "../../../assets/LoginPage/hide_password.svg";
import { useState } from "react";

function SignIn({styles}) {
  let [showPassword, setShowPassword] = useState(true)

  return (
    <>
        <div className={styles.login_title}>
            Вход
        </div>
        <div className={styles.input_container}>
            <span>Email</span>
            <input type="text" placeholder="Введите ваш email" name="email"/>
        </div>
        <div className={styles.input_container}>
            <span>Пароль</span>
            <input type={showPassword ? 'password' : 'text'} placeholder="Введите пароль" name="password"/>
            <img onClick={() => {setShowPassword(!showPassword)}} style={{cursor: 'pointer'}} src={showPassword ? show_password : hide_password} alt="password" />
        </div>
        <button>
            Вход
        </button>
        <span>
            Забыли пароль ?
        </span>
    </>
  );
}

export default SignIn;
