import styles from './Checkbox.module.css';
import checkbox_icon from '../../assets/Checkbox/checkbox_icon.svg';

function Checkbox({ value, valueChange }) {

  return (
    <>
    {value ? 
        <div className={styles.working} onClick={valueChange}>
            <img src={checkbox_icon} alt="" />
        </div>
        :
        <div className={styles.disable} onClick={valueChange}>
        </div>
    }
    </>
  );
}

export default Checkbox;
