import show_password from "../../../assets/LoginPage/show_password.svg";
import hide_password from "../../../assets/LoginPage/hide_password.svg";
import { useState } from "react";
import styles from './PasswordPage.module.css'

function PasswordPage() {

  let [showPasswordFirst, setShowPasswordFirst] = useState(false)
  let [showPasswordSecond, setShowPasswordSecond] = useState(false)

  return (
    <div className={styles.password_container}>
        <div className={styles.input_container}>
            <span>Пароль</span>
            <input type={showPasswordFirst ? 'text' : 'password'} placeholder="Введите пароль"/>
            <img onClick={() => {setShowPasswordFirst(!showPasswordFirst)}} style={{cursor: 'pointer'}} src={showPasswordFirst ? show_password : hide_password} alt="password" />
        </div>
        <div className={styles.input_container}>
            <span>Подтвердите пароль</span>
            <input type={showPasswordSecond ? 'text' : 'password'} placeholder="Введите пароль"/>
            <img onClick={() => {setShowPasswordSecond(!showPasswordSecond)}} style={{cursor: 'pointer'}} src={showPasswordSecond ? show_password : hide_password} alt="password" />
        </div>
        <button>
            Изменить пароль
        </button>
    </div>
  );
}

export default PasswordPage;
