import styles from './AddEmployeeModal.module.css'; // Импорт стилей

function AddEmployeeModal({seeModal, setAddEmployeeModal}) {


    return (
        <>
            {seeModal && <div className={styles.main_container}>
            <div className={styles.container}>
                <div className={styles.header}>
                </div>
            </div></div>}
        </>
    );
}

export default AddEmployeeModal;