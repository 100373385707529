import styles from './JobTitle.module.css';
import jobtitle_arrow from '../../../assets/EmployeesPage/jobtitle_arrow.svg';
import jobtitle_cancel from '../../../assets/EmployeesPage/jobtitle_cancel.svg';
import { useState } from 'react';
import Checkbox from '../../Checkbox/Checkbox';

function JobTitle({jobs, choosedJob, addJob}) {
  const [openModal, setOpenModal] = useState(false);

  return (
    <div className={styles.container}>
        <div className={styles.title}>
            Должность
        </div>
        <div className={styles.content_container} style={{height: openModal ? 'auto' : '44px'}}>
            <div className={styles.content_title}>
                <div className={styles.title_names}>
                    {choosedJob.length === 0 ? (
                        <div className={styles.title_names_placeholder}>Выберите должность</div>
                    ) : (
                        choosedJob.slice(0, 3).map((el, index) => {
                            return index < 2 ? (
                                <div key={index} className={styles.title_names_chosen}>
                                    <img src={jobtitle_cancel} alt="" style={{cursor: 'pointer'}} onClick={() => addJob(el)} />
                                    {el.name}
                                </div>
                            ) : (
                                <div key={index} className={styles.title_names_chosen} style={{padding: '4px 10px 4px 8px'}}>
                                    +2
                                </div>
                            );
                        })
                    )}
                </div>
                <img 
                    src={jobtitle_arrow} 
                    alt="" 
                    onClick={() => setOpenModal(!openModal)} 
                    style={{ cursor: 'pointer', transform: openModal ? 'rotate(180deg)' : '' }} 
                />
            </div>
            {openModal && (
                <div className={styles.content}>
                    {jobs.map((el) => (
                        <div key={el} className={styles.content_elem}>
                            <Checkbox 
                                value={choosedJob.includes(el)} 
                                valueChange={() => addJob(el)} 
                            />
                            {el.name}
                        </div>
                    ))}
                </div>
            )}
        </div>
    </div>
  );
}

export default JobTitle;
