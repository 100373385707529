import { Routes, Route, useLocation } from 'react-router-dom';
import MainPage from './pages/MainPage/MainPage';
import LoginPage from './pages/LoginPage/LoginPage';
import Header from './components/Header/Header';
import styles from './App.module.css';
import SettingsPage from './pages/SettingsPage/SettingsPage';
import EmployeesPage from './pages/EmployeesPage/EmployeesPage';
import StatisticsPage from './pages/StatisticsPage/StatisticsPage';

function App() {
  const location = useLocation(); // получаем текущий путь

  return (
    <>
      {location.pathname !== '/login' && <Header />}
      
      <div className={styles.App}>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/" element={<MainPage />} />
          <Route path="/statistics" element={<StatisticsPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/contact" element={<LoginPage />} />
          <Route path="/employees/*" element={<EmployeesPage />} />
          <Route path="/settings/*" element={<SettingsPage />} />
        </Routes>
      </div>
    </>
  );
}

export default App;