import { useEffect } from 'react';

const useClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      // Проверяем, если клик был внутри контейнера
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event); // Если клик был вне контейнера, вызываем handler
    };

    document.addEventListener('mousedown', listener); // Добавляем обработчик события
    return () => {
      document.removeEventListener('mousedown', listener); // Удаляем обработчик при размонтировании компонента
    };
  }, [ref, handler]);
};

export default useClickOutside;
