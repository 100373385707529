import styles from './EmployeeChangeModal.module.css'
import modal_header_icon from '../../../assets/EmployeesPage/modal_header_icon.png'
import ModalInfo from './../ModalInfo/ModalInfo';
import RatesTime from './../RatesTime/RatesTime';
import { useState, useEffect } from 'react';
import { createWorkerRequest, getJobTitles } from '../../../api/requests';
import JobTitle from '../JobTitle/JobTitle';

function EmployeeChangeModal({closeModal, resetWorkers, currentWorker}) {
  console.log(currentWorker.phone)
  const [workHours, setWorkHours] = useState({
    morning: {
      firstInput: '',
      secondInput: '',
      thirdInput: '',
      fourthInput: ''
    },
    day: {
      firstInput: '',
      secondInput: '',
      thirdInput: '',
      fourthInput: ''
    },
    evening: {
      firstInput: '',
      secondInput: '',
      thirdInput: '',
      fourthInput: ''
    },
    night: {
      firstInput: '',
      secondInput: '',
      thirdInput: '',
      fourthInput: ''
    }
  })
  const [info, setInfo] = useState({
    name: `${currentWorker.secondname} ${currentWorker.firstname} ${currentWorker.lastname}`,
    birthdate: currentWorker.birthdate,
    phone: currentWorker.phone,
    email: currentWorker.email,
    passportNumber: currentWorker.passportnumber  ,
    number1c: currentWorker.number1c,
  })
  const [choosedJob, setChoosedJob] = useState([]);
  const [jobs, setJobs] = useState([{id: 0, name: 'Директор'}, {id: 1, name: 'Работник'}, {id: 2, name: 'Повар'}]);

  useEffect(() => {
    console.log(currentWorker)
    let fetchData = async () => {
      let res = await getJobTitles();
      setJobs(res)
    }
    fetchData()
  }, []);
  
  const addJob = (val) => {
    setChoosedJob((prevChoosedJob) => 
      prevChoosedJob.includes(val) 
        ? prevChoosedJob.filter((el) => el !== val) 
        : [...prevChoosedJob, val]
    );
  };

  const updateWorkHours = (period, field, value) => {
    setWorkHours(prevWorkHours => ({
      ...prevWorkHours,
      [period]: {
        ...prevWorkHours[period],
        [field]: value
      }
    }));
  };

  const updateInfo = (field, value) => {
    setInfo((prevInfo) => ({
        ...prevInfo,
        [field]: value,
    }));
  };

  let stylesForRate = {
    gap: '70px',
    disable: false,
    first: {
      first: {
        title: 'Ставка (утро)',
        placeholder: '1.200 руб. ч.',
        width: '163px'
      },
      second: {
        title: 'Ставка переработки (утро)',
        placeholder: '1.200 руб. ч.',
        width: '163px'
      },
      third: {
        title: 'Кол. часов (утро)',
        placeholder: '8 ч.',
        width: '143px'
      },
      fourth: {
        title: 'Период (утро)',
        placeholder: 'С__:__ До__:__',
        width: '181px'
      },
    },
    second: {
      first: {
        title: 'Ставка (день)',
        placeholder: '1.200 руб. ч.',
        width: '163px'
      },
      second: {
        title: 'Ставка переработки (день)',
        placeholder: '1.200 руб. ч.',
        width: '163px'
      },
      third: {
        title: 'Кол. часов (день)',
        placeholder: '8 ч.',
        width: '143px'
      },
      fourth: {
        title: 'Период (день)',
        placeholder: 'С__:__ До__:__',
        width: '181px'
      },
    },
    third: {
      first: {
        title: 'Ставка (вечер)',
        placeholder: '1.200 руб. ч.',
        width: '163px'
      },
      second: {
        title: 'Ставка переработки (вечер)',
        placeholder: '1.200 руб. ч.',
        width: '163px'
      },
      third: {
        title: 'Кол. часов (вечер)',
        placeholder: '8 ч.',
        width: '143px'
      },
      fourth: {
        title: 'Период (вечер)',
        placeholder: 'С__:__ До__:__',
        width: '181px'
      },
    },
    fourth: {
      first: {
        title: 'Ставка (ночь)',
        placeholder: '1.200 руб. ч.',
        width: '163px'
      },
      second: {
        title: 'Ставка переработки (ночь)',
        placeholder: '1.200 руб. ч.',
        width: '163px'
      },
      third: {
        title: 'Кол. часов (ночь)',
        placeholder: '8 ч.',
        width: '143px'
      },
      fourth: {
        title: 'Период (ночь)',
        placeholder: 'С__:__ До__:__',
        width: '181px'
      },
    },
  }

  const addEmployeeFunc = async () => {
    let data = {
      firstname: info.name.split(' ')[1],
      lastname: info.name.split(' ')[0], 
      secondname: info.name.split(' ')[2],
      birthdate: info.birthdate,
      phone: info.phone,
      email: info.email,
      passportnumber: Number(info.passportNumber),
      number1c: Number(info.number1c),
      // jobs: choosedJob
    };

    console.log(data)
   
    let res = await createWorkerRequest(data)
    console.log(res)
    resetWorkers()
  }

  return (
    <div className={styles.background_container} onClick={() => closeModal(false)}>
      <div className={styles.main_container} onClick={(event) => event.stopPropagation()}>
        <div className={styles.main_header}>
          <span>
            Пользователь
          </span>
          <img src={modal_header_icon} alt="" onClick={() => closeModal(false)}/>
        </div>
        <div className={styles.main_content}>
          <div className={styles.content_header}>
            <div className={styles.header_name}>
              <div className={styles.header_name_img}></div>
              {/* <img src="" alt="" /> */}
              {/* <input type="text" value={info.name} onChange={(e) => updateInfo('name', e.target.value)} placeholder='Введите ФИО'/> */}
              <div className={styles.info_name}>{info.name}</div>
            </div>
            <div className={styles.header_jobtitle_container}>
              <JobTitle jobs={jobs} choosedJob={choosedJob} addJob={addJob} />
            </div>
          </div>
          <ModalInfo info={info} handleInfo={updateInfo}/>
          {/* <div className={styles.rates_container}>
            <div className={styles.rates_title}>Ставки</div>
            <div className={styles.rates_second}>
              <div className={styles.rates_second_name}>
                <span>Повар</span>
                <span>Шеф повар</span>
              </div>
              <div className={styles.rates_second_checkbox}>
                <input type="checkbox" />
                Индивидуальные условия
              </div>
            </div>
            <div className={styles.rates_title}>
              <RatesTime content={stylesForRate} fields={workHours} handleFields={updateWorkHours}/>
            </div>
          </div> */}
        </div>
        <div className={styles.buttons_container}>
          <div className={styles.fired}>
            <input type="checkbox" />
            Уволен
          </div>
          <div className={styles.buttons}>
            <button className={styles.delete}>
              Удалить
            </button>
            <button className={styles.save} onClick={addEmployeeFunc}>
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmployeeChangeModal;
