import React, { useState, useRef } from 'react';
import styles from './StatisticsPage.module.css'; // Импорт стилей
import Calendar from './../../components/Calendar/Calendar.jsx';
import filter_icon from '../../assets/MainPage/filter_icon.svg'
import Table from '../../components/Table/Table.jsx';

function StatisticsPage() {
  const [date, setDate] = useState(new Date())

  let setDateFunc = (val) => {
    setDate(val)
  }

  let people = ['Фирсанова Елена Андреевна', 'Дмитров Евгений Юрьевич', 'Фирсанова лена Андреевна']

  return (
    <div className={styles.main_container}>
      <div className={styles.main_header}>
        <Calendar setDateFunc={setDateFunc} />
        <div className={styles.days_container}>
            <div id='top_1_index_0'>
                <div className={styles.day_container} style={{borderLeft: '1px solid rgb(99, 102, 241)', borderRadius: '8px 0 0 8px'}}>У</div>
            </div>
            <div id='top_1_index_1'>
                <div className={styles.day_container}>Д</div>
            </div>
            <div id='top_1_index_2'>
                <div className={styles.day_container}>В</div>
            </div>
            <div id='top_1_index_3'>
                <div className={styles.day_container}>н</div>
            </div>
            <div id='top_1_index_4'>
                <div className={styles.day_container}>Сум. часов</div>
            </div>
            <div id='top_1_index_5'>
                <div className={styles.day_container}>ЗП. руб.</div>
            </div>
            <div id='top_1_index_6'>
                <div className={styles.day_container}>Перер. ч.</div>
            </div>
            <div id='top_1_index_7'>
                <div className={styles.day_container}>Перер. руб.</div>
            </div>
            <div id='top_1_index_8'>
                <div className={styles.day_container} style={{borderRadius: '0 8px 8px 0'}}>ЗП</div>
            </div>
        </div>
      </div>
      <div className={styles.main_content}>
        <Table JobTitleName="Директор" leftPart={people} widthPart="117px" canAddJobTitle='false' tableNumber='1'/>
        <Table JobTitleName="Артист" leftPart={people} widthPart="117px" canAddJobTitle='false' tableNumber='1'/>
      </div>
    </div>
  );
}

export default StatisticsPage;
