import styles from './RegistryPage.module.css';
import RatesTime from './../../EmployeesPage/RatesTime/RatesTime';
import { useEffect, useState } from 'react';
import { changeJobTitle, createJobTitle, getJobTitles,  } from '../../../api/requests';

function RegistryPage() {
  const [error, setError] = useState('');
  const [currentJobTitle, setCurrentJobTitle] = useState(0);
  const [jobTitles, setJobTitles] = useState([{
    name: '',
    number1c: '',
    description: '',
    rates: {
      morning: {
        firstInput: '',
        secondInput: '',
        thirdInput: '',
        fourthInput: ''
      },
      day: {
        firstInput: '',
        secondInput: '',
        thirdInput: '',
        fourthInput: ''
      },
      evening: {
        firstInput: '',
        secondInput: '',
        thirdInput: '',
        fourthInput: ''
      },
      night: {
        firstInput: '',
        secondInput: '',
        thirdInput: '',
        fourthInput: ''
      }
    }
  }]);

  const transformRatesToInputs = (data) => {
    return data.map((appointment) => {
      const rates = {
        morning: { firstInput: '', secondInput: '', thirdInput: '', fourthInput: '' },
        day: { firstInput: '', secondInput: '', thirdInput: '', fourthInput: '' },
        evening: { firstInput: '', secondInput: '', thirdInput: '', fourthInput: '' },
        night: { firstInput: '', secondInput: '', thirdInput: '', fourthInput: '' }
      };

      // Заполняем ставки в зависимости от workshiftid
      appointment.hourrates.forEach(rate => {
        switch (rate.workshiftid) {
          case 1: // Утро
            rates.night.firstInput = rate.ratenormative;
            rates.night.secondInput = rate.rateexcess;
            rates.night.thirdInput = rate.rateholiday;
            rates.night.fourthInput = rate.rateholidayexcess;
            break;
          case 2: // День
            rates.morning.firstInput = rate.ratenormative;
            rates.morning.secondInput = rate.rateexcess;
            rates.morning.thirdInput = rate.rateholiday;
            rates.morning.fourthInput = rate.rateholidayexcess;
            break;
          case 3: // Вечер
            rates.day.firstInput = rate.ratenormative;
            rates.day.secondInput = rate.rateexcess;
            rates.day.thirdInput = rate.rateholiday;
            rates.day.fourthInput = rate.rateholidayexcess;
            break;
          case 4: // Ночь
            rates.evening.firstInput = rate.ratenormative;
            rates.evening.secondInput = rate.rateexcess;
            rates.evening.thirdInput = rate.rateholiday;
            rates.evening.fourthInput = rate.rateholidayexcess;
            break;
          default:
            break;
        }
      });

      return {
        id: appointment.id,
        name: appointment.name,
        number1c: appointment.number1c,
        description: appointment.description,
        rates
      };
    });
  };

  // Функция для преобразования `rates` обратно в формат base, excess, и т.д.
  const transformInputsToRates = (rates) => {
    return Object.keys(rates).reduce((acc, period) => {
      const rate = rates[period];
      acc[period] = {
        base: parseFloat(rate.firstInput),
        excess: parseFloat(rate.secondInput),
        holiday: parseFloat(rate.thirdInput),
        holidayexcess: parseFloat(rate.fourthInput)
      };
      return acc;
    }, {});
  };

  const fetchData = async () => {
    let res = await getJobTitles();
    const transformedAppointments = transformRatesToInputs(res);
    setJobTitles(transformedAppointments);
  };

  useEffect(() => {    
    fetchData();
  }, []);
  

  let stylesForRate = {
    gap: '16px',
    disable: false,
    first: {
      first: {
        title: 'Ставка (утро)',
        placeholder: '1.200 руб. ч.',
        width: '232px'
      },
      second: {
        title: 'Ставка пер. (утро)',
        placeholder: '1.200 руб. ч.',
        width: '232px'
      },
      third: {
        title: 'Выходной',
        placeholder: '1.200 руб. ч.',
        width: '232px'
      },
      fourth: {
        title: 'Переработка вых.',
        placeholder: '1.200 руб. ч.',
        width: '232px'
      },
    },
    second: {
      first: {
        title: 'Ставка (день)',
        placeholder: '1.200 руб. ч.',
        width: '232px'
      },
      second: {
        title: 'Ставка пер. (день)',
        placeholder: '1.200 руб. ч.',
        width: '232px'
      },
      third: {
        title: 'Выходной',
        placeholder: '1.200 руб. ч.',
        width: '232px'
      },
      fourth: {
        title: 'Переработка вых.',
        placeholder: '1.200 руб. ч.',
        width: '232px'
      },
    },
    third: {
      first: {
        title: 'Ставка (вечер)',
        placeholder: '1.200 руб. ч.',
        width: '232px'
      },
      second: {
        title: 'Ставка пер. (вечер)',
        placeholder: '1.200 руб. ч.',
        width: '232px'
      },
      third: {
        title: 'Выходной',
        placeholder: '8 ч.',
        width: '232px'
      },
      fourth: {
        title: 'Переработка вых.',
        placeholder: '1.200 руб. ч.',
        width: '232px'
      },
    },
    fourth: {
      first: {
        title: 'Ставка (ночь)',
        placeholder: '1.200 руб. ч.',
        width: '232px'
      },
      second: {
        title: 'Ставка пер. (ночь)',
        placeholder: '1.200 руб. ч.',
        width: '232px'
      },
      third: {
        title: 'Выходной',
        placeholder: '8 ч.',
        width: '232px'
      },
      fourth: {
        title: 'Переработка вых.',
        placeholder: '1.200 руб. ч.',
        width: '232px'
      },
    },
  };

  const updaterates = (period, field, value) => {
    setError('')
    setJobTitles((prevJobTitles) => {
      const updatedJobTitles = prevJobTitles.map((jobTitle, index) => {
        if (index !== currentJobTitle) return jobTitle;
        return {
          ...jobTitle,
          rates: {
            ...jobTitle.rates,
            [period]: {
              ...jobTitle.rates[period],
              [field]: value,
            },
          },
        };
      });
      return updatedJobTitles;
    });
  };

  const updateInfo = (field, value) => {
    setError('')
    setJobTitles((prevJobTitles) => {
      const updatedJobTitles = [...prevJobTitles];
      updatedJobTitles[currentJobTitle] = {
        ...updatedJobTitles[currentJobTitle],
        [field]: value,
      };
      return updatedJobTitles;
    });
  };

  const createJobTitleFunc = () => {
    setError('')
    const { name, number1c, rates } = jobTitles[currentJobTitle];
  
    if (!name.trim()) {
      setError("Поле 'Название' не должно быть пустым");
      return;
    }
    if (!number1c.trim()) {
      setError("Поле 'Номер 1С' не должно быть пустым");
      return;
    }
  
    const periodNames = {
      morning: "Утро",
      day: "День",
      evening: "Вечер",
      night: "Ночь"
    };
    
    const periods = ["morning", "day", "evening", "night"];
    for (let period of periods) {
      const { firstInput, secondInput, thirdInput, fourthInput } = rates[period];
      if (!firstInput || !secondInput || !thirdInput || !fourthInput) {
        setError(`Все ставки в разделе '${periodNames[period]}' должны быть заполнены`);
        return;
      }
    }
    
    let data = {
      name: jobTitles[currentJobTitle].name,  // Пример данных
      number1c: `${jobTitles[currentJobTitle].number1c}`, // Пример данных
      description: jobTitles[currentJobTitle].description, // Пример данных
      rates: transformInputsToRates(jobTitles[currentJobTitle].rates)
    };

    if (jobTitles[currentJobTitle].new) {
      const { new: _, ...cleanedData } = data;
      createJobTitle(cleanedData);
      fetchData()
    } else {
      changeJobTitle(data, jobTitles[currentJobTitle].id);
    }
  };
  

  const addJobTitle = () => {
    setJobTitles([...jobTitles, { 
      new: true,
      name: '',
      number1c: '',
      description: '',
      rates: {
        morning: {
          firstInput: '',
          secondInput: '',
          thirdInput: '',
          fourthInput: ''
        },
        day: {
          firstInput: '',
          secondInput: '',
          thirdInput: '',
          fourthInput: ''
        },
        evening: {
          firstInput: '',
          secondInput: '',
          thirdInput: '',
          fourthInput: ''
        },
        night: {
          firstInput: '',
          secondInput: '',
          thirdInput: '',
          fourthInput: ''
        }
      }
     }]);
  };

  return (
    <div className={styles.container}>
        <div className={styles.first_part}>
        {jobTitles.map((el, index) => (
          <button 
            style={{background: index !== currentJobTitle && 'transparent', color: index !== currentJobTitle && 'rgb(116, 116, 116)', border: index !== currentJobTitle && '1px solid rgb(179, 179, 179)'}} 
            className={styles.first_container} 
            onClick={() => setCurrentJobTitle(index)}
            key={index}
          >
            {el.name ? el.name : "Название должности"}
          </button>
        ))}
        </div>
        <div className={styles.second_part}>
            <div className={styles.second_title_container}>
                <div className={styles.second_title_content}>
                    <div className={styles.second_input} style={{flex: 2}}>
                        <span>Название</span>
                        <input 
                          type="text" 
                          value={jobTitles[currentJobTitle].name} 
                          placeholder='Название должности' 
                          onChange={(e) => updateInfo('name', e.target.value)}
                        />
                    </div>
                    <div className={styles.second_input} style={{flex: 1}}>
                        <span>Номер 1С </span>
                        <input 
                          type="text" 
                          value={jobTitles[currentJobTitle].number1c} 
                          placeholder='Введите номер' 
                          onChange={(e) => updateInfo('number1c', e.target.value)}
                        />
                    </div>
                </div>
                <div className={styles.second_title_content}>
                    <div className={styles.second_input} style={{marginTop: '25px', width: '100%'}}>
                        <span>Описание</span>
                        <textarea 
                          type="text" 
                          value={jobTitles[currentJobTitle].description} 
                          style={{height: '123px', padding: '12px 16px'}} 
                          placeholder='Введите текст' 
                          onChange={(e) => updateInfo('description', e.target.value)}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.rates_container}>
                <RatesTime 
                  key={currentJobTitle} // добавлено для корректного рендера
                  content={stylesForRate} 
                  fields={jobTitles[currentJobTitle].rates} 
                  handleFields={updaterates}
                />
            </div>
            <div className={styles.rates_button_container}>
              {error && <div style={{color: 'red'}}>{error}</div>}
              <button className={styles.rates_button} onClick={createJobTitleFunc}>
                  Сохранить
              </button>
            </div>
        </div>

        <div className={styles.buttons_container}><button onClick={addJobTitle}>Добавить новую должность</button></div>
    </div>
  );
}

export default RegistryPage;
